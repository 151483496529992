div.form-answers {

  div.answer-group {
    margin-bottom: 4px;

    label.radio-wrapper {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .radiobutton {
        position: absolute;
        top: -7px;
        left: 5px;
        height: 25px;
        width: 25px;
        background-color: #f7f7f7;
        border-radius: 50%;
      }

      &:hover input ~ .radiobutton {
        background-color: #e9e9e9;
      }

      input:checked ~ .radiobutton {
        background-color: #89bf8e;
      }

      .radiobutton:after {
        content: "";
        position: absolute;
        display: none;
      }

      input:checked ~ .radiobutton:after {
        display: block;
      }

      .radiobutton:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }

    }

    .tinymce-inline-wrap {
        border: 1px solid #ccc;

        .tinymce-inline-content {
            padding: 12px;
            line-height: 10px;
            color: black;

            p {
                color: black;
            }
        }

        .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
            left: unset;
        }
    }
  }

}
