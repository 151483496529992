form {

  .form-group {
    margin-bottom: 24px;

    label {
      font-size: 110%;
    }

    select.form-control {
      height: 46px;
    }

  }

  span.required {
    color: red;
  }

  div.selectize-control.form-control {
    div.selectize-input {
      height: 46px;

      > div.item {
        margin-top: 5px;
      }

      input[type=text] {
        height: 32px;
      }
    }
  }

}

.dropzone {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
  border: 2px dashed #ccc !important;
  color: #a9a9a9 !important;

  .dz-preview {
    margin: 5px !important
  }

  .dz-preview .dz-image {
    border-radius: 4px !important
  }

  .dz-details .dz-size {
    display: none !important
  }

  .dz-progress {
    top: 70% !important
  }

  .dz-preview .dz-progress .dz-upload {
    background: #ffc107 !important
  }

  .dz-preview .dz-success-mark path {
    fill: #28a745 !important
  }

  .dz-preview .dz-error-mark path {
    fill: #dc3545 !important
  }

  .dz-preview:not(.dz-processing) .dz-progress {
    display: none !important
  }

  .dz-preview.dz-processing .dz-progress {
    display: block !important
  }
}