.slideout {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  background-color: #f7f7f7;
  border-left: 1px solid rgba(38, 13, 13, 0.8);
  overflow-x: hidden;
  -webkit-box-shadow: 0px 0px 51px -28px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 51px -28px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 51px -28px rgba(0,0,0,0.75);
}

.slideout.active {
  width: 33.33%;
}

.slideout-content {
  position: relative;
  top: 55px;
  width: 100%;
  margin-top: 30px;
}

.slideout a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
}

.slideout-content h1 {
  margin: 18px;
  font-size: 20px;
}

.slideout a:hover, .slideout a:focus {
  color: #4a1717;
}

.slideout .closebtn {
  cursor: pointer;
  position: absolute;
  top: 4px;
  left: 5px;
  font-size: 48px;
}

.slideout .panel {
  margin: 18px;
}

@media screen and (max-height: 450px) {
  .slideout a {font-size: 20px}
  .slideout .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

/* Make sure the TinyMCE dialog's z-index is greater than the slideout */
div.tox-tinymce-aux {
  z-index: 10000;
}
