div.filters {

    div.dropdown-menu.dropdown-filter {
        min-width: 400px;
        padding: 0px;

        div.filter-list-checkboxes {
            margin: 0px 0px 20px 20px;
            overflow-y: auto;
            max-height: 220px;
            min-height: 65px;
        }

        div.filter-list-buttons {
            margin: 10px 0px 20px 20px;
        }

        div.filter-list-search {
            background-color: #f7f7f7;
            padding: 10px 0px 20px 20px;

            input[type=text] {
                width: 94%;
                height: 34px;
                margin-top: 7px;
            }
        }

        div.filter-list-datepicker {
            margin: 10px 10px 20px 20px;
        }
    }

}
