//Variables
$background-color: #fefefe;
$body-background-color: #f7f7f7;
$primary-color: #3646a4;
$primary-color-hover: #45a2f2;
$secondary-color: #b76975;
$base-font-size: 1.4rem;

//Theme Styles Only

body {
	font-size: $base-font-size;
	font-family: $custom-font;
	background-color: $body-background-color;
}

a {
	color: $primary-color;
}

a:hover, a:focus {
	color: $primary-color-hover;
}

.form-control:focus {
	border: 1px solid $primary-color;
	box-shadow: inset 0px 0px 0px 1px $primary-color;
}

.navbar-custom {
	background: $background-color;
}

.navbar-header .navbar-brand {
	margin: 0 0 0 -10px;
	padding: 0;
}

.navbar-header .navbar-brand span {
	color: $primary-color;
}

.dropdown-menu li a:hover {
	color: $primary-color;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary, .btn-primary.disabled, .btn-primary[disabled], fieldset[disabled] .btn-primary, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled:active, .btn-primary[disabled]:active, fieldset[disabled] .btn-primary:active, .btn-primary.disabled.active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary.active {
	background-color: $primary-color!important;
	border-color: $primary-color!important;
}

.btn-link, .page-link, .text-primary {
	color: $primary-color!important;
}

.btn-link:focus, .btn-link:hover, .page-link:focus, .page-link:hover, a.text-primary:focus, a.text-primary:hover {
	color: $primary-color-hover!important;
}

.label-info {
	background-color: $primary-color;
}

.label-info[href]:hover, .label-info[href]:focus {
	background-color: $primary-color;
}

.panel-primary > .panel-heading {
	background-color: $primary-color;
}
.timeline-badge.primary {
	background-color: $primary-color !important;
}

.progress-bar {
	background-color: $primary-color;
}

.nav-tabs li.active a {
	color: $primary-color;
}

.nav-pills li.active a, .nav-pills li.active a:hover {
	background: $primary-color;
}

.navbar input:focus {
	border: 1px solid $primary-color;
	box-shadow: inset 0px 0px 0px 1px $primary-color;
}

.sidebar {
	background: $primary-color;
}

.sidebar ul.nav {
	margin: 0px;
}

.sidebar .form-control {
	border: 1px solid #2f2b33;
	box-shadow: inset 0px 0px 0px 1px #2f2b33;
	color: #fff;
	background: $primary-color;
}

.form-control:focus {
	border: 1px solid $primary-color;
	box-shadow: inset 0px 0px 0px 1px $primary-color;
}

.sidebar ul.nav ul.children,
.sidebar ul.nav ul.children li a {
	background: $secondary-color;
	color: #fff;
}

.sidebar ul.nav li a {
	color: #fff;
	font-size: 110%;
}

.sidebar ul.nav a:hover, .sidebar ul.nav li.parent ul li a:hover {
	background-color: $primary-color;
	color: $primary-color-hover;
}

.sidebar ul.nav ul.children > li:hover, .sidebar ul.nav ul.children li > a:hover {
	background: $secondary-color !important;
}

.sidebar ul.nav .active a, .sidebar ul.nav li.parent a.active, .sidebar ul.nav .active > a:hover, .sidebar ul.nav li.parent a.active:hover, .sidebar ul.nav .active > a:focus, .sidebar ul.nav li.parent a.active:focus {
	background-color: lighten($primary-color, 10%);
}

.sidebar ul.nav li.current a {
	background-color: $primary-color;
}

.profile-usertitle-name {
	color: #fff;
}

.profile-sidebar {
	border-bottom: 1px solid rgba(255, 255, 255, .1);
}

div.flash.row {
	margin-top: 30px;
}

.sidebar ul.nav li.divider {
	padding: 12px;
}