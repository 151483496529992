div.contextmenu {
  padding: 0;
  display: none;
  box-shadow: none;
  background-color: #ddd;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    cursor: pointer;
  }

  li.active {
    background-color: #f7f7f7;

    a {
      font-weight: bold;
    }
  }

  li a {
    display: block;
    color: #000000;
    padding: 8px 16px;
    text-decoration: none;
  }

  li a:hover {
    background-color: #eee;
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  div.contextmenu {
    display: block;
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 16.66%;
    z-index: 1000;
    display: block;
    margin: 0;
    padding: 0;
    padding-top: 8px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
