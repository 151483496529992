.dropzone {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
  border: 2px dashed #ccc !important;
  color: #a9a9a9 !important;

  .dz-preview {
    margin: 5px !important
  }

  .dz-preview .dz-image {
    border-radius: 4px !important
  }

  .dz-progress {
    top: 70% !important
  }

  .dz-preview .dz-progress .dz-upload {
    background: #ffc107 !important
  }

  .dz-preview .dz-success-mark path {
    fill: #28a745 !important
  }

  .dz-preview .dz-error-mark path {
    fill: #dc3545 !important
  }

  .dz-preview:not(.dz-processing) .dz-progress {
    display: none !important
  }

  .dz-preview.dz-processing .dz-progress {
    display: block !important
  }

  .dz-preview .dz-details .dz-filename span, .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .dz-preview {
    a.dz-remove {
      display: none;
    }

    div.dz-toolbar {
      text-align: center;
      position: relative;
      top: -18px;
      z-index: 100;

      a {
        cursor: pointer;
      }

      a {
        font-size: 90%;
        display: inline-block;
        border-radius: 50%;
        box-shadow: 0px 0px 2px #888;
        padding: 0.3em;
        color: white;
        border: 2px solid white;
        width: 30px;
        height: 30px;
        background-color: #1ebfae;

        .fa {
          cursor: pointer !important;
        }
      }

      a.dz-toolbar-remove {
        background-color: #f00;
      }

      a.dz-toolbar-download {
        display: none;
      }

      a.dz-toolbar-pending {
        background-color: #d2d2d2;
        display: none;
        cursor: default;

        .fa {
          cursor: default !important;
        }
      }
    }
  }

  .dz-preview .dz-image {
    width: 120px;
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: linear-gradient(to bottom, #eee, #ddd);
  }
}