nav div.navbar-header ul.navbar-right {
  li.dropdown {
    background-color: $primary-color;

    a.dropdown-toggle {
      width: auto;
      min-width: 40px;
    }

    ul.dropdown-menu li button.link {
      height: 19px;
      color: #444444;
      position: relative;
      display: block;
      padding: 0px;
      background: transparent;
      border: 0px;
      text-align: left;
      width: 100%;
    }
  }

}