@import url(https://fonts.googleapis.com/css?family=Roboto:500);

body.login-page {
  input,
  .btn {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    margin: 5px 0;
    opacity: 0.85;
    display: inline-block;
    font-size: 17px;
    line-height: 20px;
    text-decoration: none;
  }

  input {
    border: 1px solid #ddd;
  }

  label {
    padding: 12px;
    margin: 5px 0;
  }

  div.login {
    background-color: $background-color;

    .google-btn {
      margin: auto;
      width: 290px;
      height: 42px;
      background-color: #4285f4;
      border-radius: 2px;
      box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);

      .google-icon-wrapper {
        position: absolute;
        margin-top: 1px;
        margin-left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: #fff;
      }

      .google-icon {
        position: absolute;
        margin-top: 11px;
        margin-left: 11px;
        width: 18px;
        height: 18px;
      }

      .btn-text {
        float: right;
        margin: 11px 11px 0 0;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0.2px;
        font-family: "Roboto";
      }

      &:hover {
        box-shadow: 0 0 6px #4285f4;
      }

      &:active {
        background: #1669F2;
      }
    }

    div.social-login {
      margin: 2.75rem 0px;
    }

    div.separator {
      margin: 2.75rem 0px;

      & > div {
        width: 100%;
        height: 12px;
        border-bottom: 1px solid #ccc;
        text-align: center;

        span {
          font-size: 16px;
          background-color: #fff; 
          color: #ccc;
          padding: 0 6px;
        }
      }
    }
  }
}